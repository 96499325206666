.bee-audit-overview {
  .bee-audit-ui-auff-beschreibung {
    & > div:first-child {
      padding-bottom: 0.75rem;
    }
    & > div:not(:first-child) {
      padding-left: 0.5rem;
    }
  }

  .bee-audit-auffalligk-table {
    padding-top: 0px;
    margin-top: 1.25rem;
  }
  .audit-ui-auff-bold {
    font-weight: 600;
  }
}
