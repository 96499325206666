.bee-audit-liegenschaft-prev.p-card .p-card-body {
  padding: 0px;
  overflow: hidden;
}

.bee-audit-liegenschaft-prev.p-card .p-card-content {
  max-width: 100%;
  padding: 0px;
}

/* Cardview */

.bee-audit-liegenschaft-prev-card.p-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 27px 3px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 4px;
  padding-bottom: 10px;
  margin: 0px;
  position: relative;
}

.bee-audit-liegenschaft-prev-card-headline {
  padding: 1em 0.75rem 0.5em 1.5rem;
  background-color: var(--secondary-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.bee-audit-liegenschaft-prev-card-headline div {
  font-size: 0.7em;
  color: var(--primary-color-text);
  font-weight: 800;
  line-height: 1.1em;
  height: 1.5em;
  width: calc(100% - 40px);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  display: block;
  word-break: break-all;
}

.bee-audit-liegenschaft-prev-card-headline h2 {
  font-size: 1em;
  line-height: 1.2em;
  height: 2.5em;
  margin: 0em;
  margin-bottom: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  width: calc(100% - 40px);
  padding: 0rem;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bee-audit-liegenschaft-prev-card-table {
  font-size: 0.95em;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}

.bee-audit-liegenschaft-prev-card-table .bee-badge-primary {
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  font-size: 0.8rem;
}

.bee-audit-liegenschaft-prev-card-img > div {
  height: 180px;
  overflow: hidden;
}

.bee-audit-liegenschaft-prev-card-img .p-skeleton {
  overflow: hidden;
  padding-bottom: 10px;
  width: inherit !important;
  height: inherit !important;
  border-radius: 0px;
}

.bee-audit-liegenschaft-prev-card-img > div img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
}

.bee-audit-liegenschaft-prev-card-edit-btn {
  position: absolute;
  right: 0px;
  margin-top: 0.5em;
  margin-right: 0.5em;
}

/* Hover deactivated */

.bee-audit-liegenschaft-prev-card.p-card.bee-audit-liegenschaft-prev-noHover:active {
  transform: scale(0.98) translateZ(0);
  -ms-transform: scale(0.98) translateZ(0);
  -webkit-transform: scale(0.98) translateZ(0);
  filter: brightness(95%);
  transition: all 0.05s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

/* Hover activated */

.bee-audit-liegenschaft-prev-card.p-card.bee-audit-liegenschaft-prev-hover:hover {
  transform: scale(1.02) translateZ(0);
  -ms-transform: scale(1.02) translateZ(0);
  -webkit-transform: scale(1.02) translateZ(0);
  transition: all 0.07s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.bee-audit-liegenschaft-prev-card.p-card.bee-audit-liegenschaft-prev-hover:active {
  filter: brightness(0.9);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Listview */

.bee-audit-liegenschaft-prev-list.p-card {
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  margin: 5px !important;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 20px 0px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.bee-audit-liegenschaft-prev-list-headline {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding: 0 0.75rem;
  height: 100%;
  vertical-align: top;
  background-color: var(--secondary-color);
  width: 70%;
}

.bee-audit-liegenschaft-prev-list-headline div {
  line-height: 1.1em;
  height: 1.2em;
  font-size: 0.7em;
  color: var(--secondary-color-text);
  font-weight: 800;
  margin: 1em 0 0.6em 0;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.bee-audit-liegenschaft-prev-list-headline h2 {
  margin-top: 0.2em;
}

.bee-audit-liegenschaft-prev-list-edit-btn {
  position: absolute;
  right: 0px;
  margin: 5px 5px 0px 0px;
  vertical-align: top;
}

.bee-audit-liegenschaft-prev-list-table {
  vertical-align: middle;
  width: 30%;
  min-width: 200px;
  float: left;
  margin-right: 3rem;
  font-size: 0.9em;
  padding-left: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bee-audit-liegenschaft-prev-list-table table tr td:first-child {
  padding-right: 5px;
}

.bee-audit-liegenschaft-prev-list-table table tr:first-child td:first-child {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bee-audit-liegenschaft-prev-list-table .bee-badge-primary {
  min-width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  font-size: 0.8rem;
}

.bee-audit-liegenschaft-prev-list-content {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.bee-audit-liegenschaft-prev-list-content h2 {
  font-size: 1em;
  line-height: 1.2em;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

/* Hover deactivated */

.bee-audit-liegenschaft-prev-list.p-card.bee-audit-liegenschaft-prev-noHover:active {
  transform: scale(0.99) translateZ(0);
  -ms-transform: scale(0.99) translateZ(0);
  -webkit-transform: scale(0.99) translateZ(0);
  filter: brightness(95%);
  transition: all 0.05s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

/* Hover activated */

.bee-audit-liegenschaft-prev-list.p-card.bee-audit-liegenschaft-prev-hover:hover {
  transform: scale(1.02) translateZ(0);
  -ms-transform: scale(1.02) translateZ(0);
  -webkit-transform: scale(1.02) translateZ(0);
  transition: all 0.07s ease fade;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.bee-audit-liegenschaft-prev-list.p-card.bee-audit-liegenschaft-prev-hover:active {
  filter: brightness(0.9);
}

/* responsive list layout */

@media only screen and (min-width: 1100px) {
  .bee-audit-liegenschaft-prev-list-table {
    width: 20%;
  }
  .bee-audit-liegenschaft-prev-list-headline {
    width: 80%;
  }
  .bee-audit-liegenschaft-prev-list-headline {
    padding-right: 3rem;
  }
}

@media only screen and (max-width: 850px) {
  .bee-audit-liegenschaft-prev-list-table {
    width: 40%;
  }
  .bee-audit-liegenschaft-prev-list-headline {
    width: 60%;
  }
  .bee-audit-liegenschaft-prev-list-headline {
    padding-right: 3rem;
  }
}

@media (max-width: 620px) {
  .bee-audit-liegenschaft-prev-list-table {
    display: none;
  }
  .bee-audit-liegenschaft-prev-list-content {
    background-color: var(--secondary-color);
    border-radius: 4px;
    min-width: 10px;
    padding-right: 3rem;
  }
}
