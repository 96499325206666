.bee-audit-stakeholder-table {
  padding: 10px 0px;
}

.bee-audit-stakeholder-table .p-datatable-tbody .p-datatable-emptymessage td {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.bee-audit-stakeholder-table .p-datatable-wrapper {
  border-radius: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bee-audit-stakeholder-table table th:first-child,
.bee-audit-stakeholder-table table td:first-child {
  padding-left: 1em !important;
}

.bee-audit-stakeholder-table table th:last-child,
.bee-audit-stakeholder-table table td:last-child {
  padding-right: 1em !important;
}

.bee-audit-stakeholder-table .p-datatable-thead th,
.bee-audit-stakeholder-table .p-datatable-tbody td,
.bee-audit-stakeholder-table .p-datatable-tbody td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bee-audit-stakeholder-table .p-datatable-thead th {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.bee-audit-stakeholder-table .p-datatable-tbody td {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

/* cols */

.bee-audit-stakeholder-table-col-name {
  width: 38%;
}

.bee-audit-stakeholder-table-col-role {
  width: 24%;
}

.bee-audit-stakeholder-table-col-company {
  width: 38%;
}

.bee-audit-stakeholder-table-col-participation {
  width: 13em;
}

.bee-audit-stakeholder-table-col-shared {
  text-align: center !important;
  width: 5.5em;
}

.bee-audit-stakeholder-table-col-action {
  padding: 1rem !important;
}

.bee-audit-stakeholder-table-col-action-oneBtn {
  width: calc(2.25rem + 3px + 2rem);
}

.bee-audit-stakeholder-table-col-action-twoBtn {
  width: calc(4.5rem + 3px + 2rem);
}

.bee-audit-stakeholder-table-col-action button {
  margin-right: 3px;
}

/* dark */

.bee-audit-stakeholder-table.bee-audit-stakeholder-table-dark
  table
  .p-datatable-thead
  tr
  th {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

/* light */

.bee-audit-stakeholder-table.bee-audit-stakeholder-table-light
  table
  .p-datatable-thead
  tr
  th {
  font-size: 1.1em;
  color: var(--primary-color);
}

.bee-audit-stakeholder-table.bee-audit-stakeholder-table-light
  table
  .p-datatable-tbody
  tr:nth-child(odd) {
  background-color: var(--primary-color-4);
}

/* responsive */

@media (max-width: 450px) {
  .bee-audit-stakeholder-table table thead {
    display: none;
  }
  .bee-audit-stakeholder-table table,
  .bee-audit-stakeholder-table table tbody,
  .bee-audit-stakeholder-table table tr,
  .bee-audit-stakeholder-table table td {
    display: block;
    width: 100%;
    text-align: left;
    float: left;
    clear: left;
    border: 0 none;
  }
  .bee-audit-stakeholder-table table tbody tr {
    margin-bottom: 10px;
  }
  .bee-audit-stakeholder-table table tbody tr:nth-child(odd) {
    background: var(--primary-color-4);
  }
  .bee-audit-stakeholder-table table td p,
  .bee-audit-stakeholder-table
    .p-datatable.p-datatable-sm
    .p-datatable-tbody
    > tr
    > td,
  .bee-audit-stakeholder-table table td div {
    text-align: left !important;
    position: relative;
    border: none;
  }
  .bee-audit-stakeholder-table table td p::before,
  .bee-audit-stakeholder-table table td div::before {
    content: attr(headername);
    padding: 0.4rem;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin: -0.4em 1em -0.6em 0.4rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  .bee-audit-stakeholder-table table th:first-child,
  .bee-audit-stakeholder-table table td:first-child {
    padding-left: 0.4rem !important;
  }
  .bee-audit-stakeholder-table table > tbody > tr:first-child td:first-child {
    padding-top: 10px !important;
  }
  .bee-audit-stakeholder-table table > tbody > tr td p {
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
  }
  .bee-audit-stakeholder-table table tbody > tr > td:last-child {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    padding-bottom: 10px !important;
  }
}
