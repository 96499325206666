@charset "UTF-8";
:root {
  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: rgba(0, 0, 0, 0.04);
  --surface-d: rgba(0, 0, 0, 0.12);
  --surface-e: rgba(0, 0, 0, 0.18);
  --surface-f: #ffffff;
  --text-color: rgba(0, 0, 0, 0.87);
  --text-color-secondary: rgba(0, 0, 0, 0.6);
  --primary-color: #131a1c;
  --primary-dark-color: #0f1516;
  --primary-light-color: #424849;
  --primary-light-active-color: #cacbcc;
  /* shades */
  --primary-color-100: #131a1c;
  --primary-color-92: #131a1ceb;
  --primary-color-76: #131a1cc2;
  --primary-color-68: #131a1cad;
  --primary-color-50: #131a1c80;
  --primary-color-32: #131a1c52;
  --primary-color-16: #131a1c29;
  --primary-color-12: #131a1c1f;
  --primary-color-4: #131a1c0a;
  --secondary-color: #e84e0f;
  --secondary-dark-color: #ba421b;
  --secondary-light-color: #ed754e;
  /* shades */
  --secondary-color-100: #e84e0f;
  --secondary-color-92: #e85222eb;
  --secondary-color-76: #e85222c2;
  --secondary-color-68: #e85222ad;
  --secondary-color-50: #e8522280;
  --secondary-color-32: #e8522252;
  --secondary-color-20: #e8522233;
  --secondary-color-16: #e8522229;
  --secondary-color-12: #e852221f;
  --secondary-color-4: #e852220a;

  --tertiary-color: #fd9902;
  --tertiary-dark-color: #cd8800;
  --tertiary-light-color: #f9b123;
  --quaternary-color: #467ec7;
  --quaternary-dark-color: #273e71;
  --quaternary-light-color: #4d7ada;

  --error-color: #af1524;
  --error-dark-color: #b71c1c;
  --valid-color: #1d8c24;
  --valid-dark-color: #1b5e20;
  --warning-color: #bf8001;
  --info-color: #017696;
  --neutral-color: #757575;
  --primary-color-text: #ffffff;
  --secondary-color-text: #ffffff;
  --primary-border-color: rgba(0, 0, 0, 0.38);
  --label-background-color: #ffffff;
  --font-family: Arial, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  --surface-0: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
}

/* roboto-regular - latin-ext_latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./fonts/roboto-v20-latin-ext_latin-regular.woff2") format("woff2"),
    url("./fonts/roboto-v20-latin-ext_latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin-ext_latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("./fonts/roboto-v20-latin-ext_latin-500.woff2") format("woff2"),
    url("./fonts/roboto-v20-latin-ext_latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin-ext_latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./fonts/roboto-v20-latin-ext_latin-700.woff2") format("woff2"),
    url("./fonts/roboto-v20-latin-ext_latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
