.bee-audit-data {
  /* headline */
  .bee-audit-data-headline {
    padding: 20px 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  h1 {
    display: inline;
    white-space: nowrap;
  }

  .bee-audit-data-headline-btns {
    float: right;
    padding-right: 0px;

    button {
      margin-right: 0.5rem;

      &:last-of-type {
        margin-right: 0px;
      }
    }
  }

  /* Sections */
  .bee-audit-data-grunddaten-section {
    padding: 10px 0 20px 0;

    div {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .grid {
      padding-bottom: 5px;
    }
  }

  .bee-audit-data-doc-section,
  .bee-audit-data-stakeholder-section,
  .bee-audit-data-auffalligk-section,
  .bee-audit-data-versions-section,
  .bee-audit-data-setup-section,
  .bee-audit-data-basis-section {
    padding-bottom: 20px;
  }
}
