.bee-login {
  height: 100vh;
  max-width: 100vw;
  margin: 0px;

  .p-card {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

    .p-card-body {
      padding-left: 1.75rem;
      padding-right: 1.75rem;

      .bee-login-header {
        text-align: center;
      }
      .bee-login-login-btn {
        position: relative;

        .p-button {
          width: 100%;
        }

        /* loading state */
        .p-progress-spinner {
          position: absolute;
          height: 26px;
          width: 26px;
          right: 10px;
          top: 5px;
        }
      }
    }
  }
}
