.bee-audit-lv-treetable.p-treetable {
  /* allg */
  .bee-cur-input-container {
    width: 100%;
  }

  .contract-col > div,
  .comment-col > div,
  .quality-col > div,
  .quantity-col > div {
    padding: 0.25rem 0.75rem;
  }
  &.p-treetable-auto-layout > .p-treetable-wrapper {
    overflow-x: unset;
  }

  /* cols collapsed */

  td.contract-col,
  td.quantity-col,
  td.quality-col,
  td.comment-col {
    border-width: 0 1px 0px 1px !important;
    border-collapse: collapse;
  }

  /* contract col */
  .contract-col {
    width: 50%;
  }

  /* quantity col */
  .quantity-col {
    width: var(--widthQuantity);

    &.collapsed {
      /* header */

      .quantity-col-header-text {
        display: none;
      }

      /* body */
      .activation-td,
      .einheit-td,
      .ep-td,
      .platzhalter,
      .nachtrag-td {
        display: none;
      }
      .menge-td {
        visibility: hidden;
      }
    }
  }

  /* quality col */
  .quality-col {
    width: var(--widthQuality);

    &.collapsed {
      /* header */
      .quality-col-header-text {
        display: none;
      }

      /* body */

      .platzhalter,
      .fulfillment-slider-td,
      .percentplatzhalter,
      .fulfillment-drop-td {
        display: none;
      }

      .bee-audit-lv-quality.pos {
        .fulfillment-percentage-td {
          visibility: hidden;
        }
      }
    }

    &.expanded {
      .fulfillment-percentage-td-collapsed {
        display: none;
        visibility: hidden;
      }
    }
  }

  /* comment col */
  .comment-col {
    width: var(--widthComment);
    min-width: 8em;
  }

  /* header */

  .p-treetable-thead {
    /* sticky */

    position: sticky;
    position: -webkit-sticky;
    top: 10px;
    top: 7rem;
    z-index: 150;

    > tr > th {
      padding-top: 0.25rem;
      padding-bottom: 0rem;
      background: var(--primary-color);
      color: var(--primary-color-text);

      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }

      .p-column-title {
        display: inline-flex;
        align-items: center;

        input {
          text-align: right;
          background-color: inherit;
          color: var(--primary-color-text);
          border-color: transparent;
        }
        label {
          background-color: transparent;
          color: var(--primary-color-text);
          top: -0.3rem !important;
        }
      }

      /* expanded */
      &.expanded {
        background-color: var(--primary-light-color);
      }

      .contract-col-header .col-name-td input,
      .quantity-col-header-text input,
      .quality-col-header-text input,
      .comment-col-header-text input {
        text-align: left;
      }
      .contract-col-header-sumGP,
      .quantity-col-header-sumGP,
      .quality-col-header-sumGP {
        width: 9em;

        input {
          text-align: left;
        }
      }

      /* quality header mean reduction */
      /* percentageInput */
      .quality-col-header-meanReduction {
        width: 5em;

        input {
          width: 100%;
          text-align: left !important;
        }
        .p-float-label input.p-filled ~ label,
        .p-float-label .p-inputwrapper ~ label {
          position: absolute;
          right: 0.5rem;
          left: auto;
          top: 0.7rem !important;
          font-size: 1.1em !important;
          background-color: transparent;
          color: var(--primary-color-text);
        }
      }

      .contract-header-platzhalter {
        width: 2.5rem;
      }
    }
  }
  /* body */

  .p-treetable-tbody {
    .rowColored {
      background-color: var(--secondary-color-16) !important;
    }
    /* allg */
    & > tr {
      border-bottom: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;

      & > td {
        padding: 0rem;
        vertical-align: top;
      }
    }
    & > tr:not(.colored) {
      /* expanded */
      &.expanded {
        background-color: white;
      }
    }

    td.contract-col {
      display: inline-flex;
      width: 100%;
      align-items: center;
    }

    /* background */

    .lv-depth-1,
    .lv-depth-1 label,
    .lv-depth-1:not(.p-highlight):focus {
      background-color: var(--surface-200);
    }
    .lv-depth-2,
    .lv-depth-2 label,
    .lv-depth-2:not(.p-highlight):focus {
      background-color: var(--surface-100);
    }

    .lv-depth-3,
    .lv-depth-3 label,
    .lv-depth-3:not(.p-highlight):focus {
      background-color: #fcfcfc;
    }

    .lv-depth-pos-2:not(.p-highlight):focus,
    .lv-depth-pos-1:not(.p-highlight):focus,
    .lv-depth-pos-3:not(.p-highlight):focus {
      background-color: #ffffff;
    }
  }

  /* readOnly Input*/

  .input-readOnly input {
    width: 100%;
    text-align: left !important;
    padding: 0rem 0.75rem !important;
    border: none;
  }
}

/* responsiveness tablet*/
.bee-audit-lv-treetable.sm {
  .contract-col {
    width: 50%;
  }

  .quantity-col {
    width: 60%;

    .bee-checkbox-value {
      display: none;
    }

    &.collapsed {
      width: 7em;
    }
  }

  .quality-col {
    width: 50%;
    &.collapsed {
      width: 7em;

      /* header */
      .quality-col-header-meanReduction {
        display: none;
      }

      /* body */

      .fulfillment-drop-td,
      .fulfillment-percentage-td,
      .fulfillment-percentage-td-collapsed,
      .platzhalter-fulfillment {
        display: none;
      }
      .bee-audit-lv-quality.pos {
        .fulfillment-percentage-td {
          visibility: hidden;
          height: 2rem;
        }
      }
    }
  }

  .comment-col {
    width: 60%;
    &.collapsed {
      width: 3rem;
      min-width: unset;
      .comment-col-header-text {
        display: none;
      }
    }
  }

  /* smaller price input fields */

  .contract-col-header-sumGP {
    width: 6em !important;
    .gp-td,
    .ep-td {
      width: 6em !important;
    }
  }

  .contract-col-header-sumGP,
  .quantity-col-header-sumGP,
  .quality-col-header-sumGP,
  .gp-td {
    width: 7em !important;
  }
}
