.bee-audit-lv-quality {
  .gp-td {
    width: 9em;
  }

  /* percentageInput */
  .fulfillment-percentage-td,
  .fulfillment-percentage-td-collapsed,
  .percentplatzhalter {
    width: 5em;

    input {
      width: 100%;
      text-align: left !important;
    }
    .p-float-label input.p-filled ~ label,
    .p-float-label .p-inputwrapper ~ label {
      position: absolute;
      right: 0.5rem;
      left: auto;
      top: 0.75rem !important;
      font-size: 1.1em !important;
      background-color: transparent;
    }
  }
  /* colored */
  &.colored {
    .fulfillment-percentage-td .p-inputtext,
    .fulfillment-percentage-td label,
    .fulfillment-percentage-td-collapsed .p-inputtext,
    .fulfillment-percentage-td-collapsed label,
    .gp-td .p-inputtext {
      color: var(--quaternary-color);
      font-weight: 600;
    }
  }

  /* readonly */
  &.readOnly,
  .readOnly,
  &.deactivated {
    .bee-txt-input,
    .bee-cur-input-field,
    .bee-num-input,
    .p-inputtext,
    .p-dropdown {
      border-color: transparent;
      background-color: transparent !important;
    }

    .bee-cur-input-label,
    .bee-txt-input-label {
      background-color: transparent !important;
    }
  }

  /* deactivated */
  &.deactivated .p-inputtext {
    color: var(--surface-500);
  }
  &.deactivated .gp-td .bee-cur-input-field {
    text-decoration: line-through;
  }
}
