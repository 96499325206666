.bee-online-ls-gebaeude-headline {
    margin: 1rem 1.5rem;
    padding: 0rem 0.5rem;
}

.bee-online-ls-gebaeude-tabview {
    padding: 0 1rem;
}

.bee-online-ls-gebaeude-tabview .p-tabview .p-tabview-panels {
    padding: 0px;
}

.online-ls-detail-show-buildings-btn {
    margin: 0 2rem;
}