.bee-audit-multi-choice-btn {
  border-radius: 4px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.bee-audit-multi-choice-btn button {
  width: 100%;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 2.3rem;
  white-space: break-spaces;
  line-height: 1.5rem;
}

.bee-audit-multi-choice-selected {
  background-color: rgba(var(--weightcolor), 0.3);
}

.bee-audit-question-category-headline {
  padding-left: 10px;
}

.bee-audit-multi-choice-btn button .p-button-label .bee-audit-q-description {
  font-weight: 400;
}

/* readonly */

.bee-audit-multi-choice-btn.bee-audit-multi-choice-readOnly {
  box-shadow: none;
}

.bee-audit-multi-choice-btn.bee-audit-multi-choice-readOnly .p-button-outlined {
  border: none;
  pointer-events: none;
}

.bee-audit-multi-choice-btn.bee-audit-multi-choice-readOnly
  .p-button-outlined
  .p-button-label {
  font-weight: 400;
  opacity: 1;
}

.bee-audit-multi-choice-btn.bee-audit-multi-choice-readOnly.bee-audit-multi-choice-selected
  .p-button-outlined
  .p-button-label {
  font-weight: 500;
  opacity: 1;
}

/* color corner according to weight */
.bee-audit-multi-choice-btn button:after {
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
  right: 0;
  border-width: 0 1.5em 1.5em 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(var(--weightcolor), 0.5);
  border-right-color: rgba(var(--weightcolor), 0.5);
  content: "";
}

.bee-audit-multi-choice-selected button:after {
  position: absolute;
  border-style: solid;
  border-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  content: "";
}
