.bee-audit-select {
  h1 {
    display: inline-flex;
  }
  .p-progress-spinner {
    display: inline-flex;
    width: 28px;
    height: 28px;
    margin-left: 20px;
  }
  .bee-error-label {
    display: block;
  }

  /* content */
  .bee-audit-select-content {
    h2 {
      margin-top: 0px;
    }
  }
  /* footer */
  .bee-audit-select-footer-buttons {
    margin: 20px 0 0 0;
  }
}
