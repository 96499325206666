.bee-audit-setup-type {
  margin-bottom: 15px;
}

.bee-audit-setup .bee-audit-setup-LV-btns,
.bee-audit-setup .bee-audit-setup-leistung {
  padding-top: 1rem;
}

.bee-audit-setup-LV-btns {
  .col-12 {
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  button {
    width: 100%;
    height: 100%;
  }
}
.bee-audit-setup-lv-upload-successful {
  text-align: center;
  padding: 1rem 0 0 0;
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 1.2rem;
}

.bee-audit-setup-btns {
  margin: 20px 0 0 0;
  button {
    margin-left: 0.5rem;
  }
}

/* btns responsive */
@media screen and (min-width: 500px) {
  .bee-audit-setup-LV-btns .sm\:col-4 {
    width: 33.3%;
  }
}

@media screen and (min-width: 1500px) {
  .bee-audit-setup-LV-btns button {
    width: 80%;
  }
}
