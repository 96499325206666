.bee-audit-lv-position-dialog.p-dialog {
    width: 70vw;
}

.bee-audit-lv-position-dialog.p-dialog .p-dialog-content {
    padding-top: 1.0em;
}

.bee-audit-lv-position-dialog.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1.5rem;
    font-weight: 800;
    padding: 0.5rem;
}


.bee-audit-lv-position-dialog .p-dialog-content {
    padding-bottom: 0.5rem;
}

/* footer */

.bee-audit-lv-position-dialog .p-dialog-footer {
    padding: 0 1.25rem 1.25rem 1.25rem
}

.bee-audit-lv-position-dialog-btns {
    padding: 0.5rem 0;
}

.bee-audit-lv-position-dialog-btns div {
    margin: 0px;
}

.bee-audit-lv-position-dialog-btns button {
    margin-left: 0.5rem;
}

/* primary */

.bee-audit-lv-position-dialog-primary.p-dialog .p-dialog-header {
    background-color: var(--primary-color)
}

.bee-audit-lv-position-dialog-primary.p-dialog .p-dialog-header .p-dialog-title {
    color: var(--primary-color-text);
}


.bee-audit-lv-position-dialog-primary.p-dialog .p-dialog-header .p-dialog-header-icons button {
    color: var(--primary-color-text);
}

.bee-audit-lv-position-dialog-primary.p-dialog .p-dialog-header-icon:enabled:hover {
    background: var(--primary-light-color) !important;
    color: var(--primary-color-text) !important;
}

.bee-audit-lv-position-dialog-primary.p-dialog .p-dialog-header-icon:active {
    background: var(--primary-color-68) !important;
    color: var(--primary-color-text) !important;
}

.bee-audit-lv-position-dialog-primary.p-dialog .p-dialog-header-icon:focus {
    background: var(--primary-color-76) !important;
    color: var(--primary-color-text) !important;
}

/* secondary */

.bee-audit-lv-position-dialog-secondary.p-dialog .p-dialog-header {
    background-color: var(--secondary-color)
}

.bee-audit-lv-position-dialog-secondary.p-dialog .p-dialog-header .p-dialog-title {
    color: var(--secondary-color-text);
}

.bee-audit-lv-position-dialog-secondary.p-dialog .p-dialog-header .p-dialog-header-icons button {
    color: var(--secondary-color-text);
}

.bee-audit-lv-position-dialog-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: var(--secondary-light-color);
    color: var(--secondary-color-text);
}

.bee-audit-lv-position-dialog-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:active {
    background: var(--secondary-color-68);
    color: var(--secondary-color-text);
}

.bee-audit-lv-position-dialog-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:focus {
    background: var(--secondary-color-76);
    color: var(--secondary-color-text);
}



@media (max-width:576px) {
    .bee-audit-lv-position-dialog.p-dialog {
        width: 95vw;
    }
}

@media (min-width:576px) and (max-width:768px) {
    .bee-audit-lv-position-dialog.p-dialog {
        width: 90vw;
    }
}