.bee-audit-dialog-sync.p-dialog {
    width: 50vw;
}

.bee-audit-dialog-sync.p-dialog .p-dialog-header {
    padding: 1.0rem;
}

.bee-audit-dialog-sync.p-dialog .p-dialog-content {
    padding-top: 1.0rem;
    padding-bottom: 0.75rem;
}

.bee-audit-dialog-sync.p-dialog .bee-loading-spinner-mixed.p-progress-spinner {
    width: 80px;
    height: 80px;
    display: block
}

.bee-audit-dialog-sync.p-dialog .bee-error-label {
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    display: block;
}

.bee-audit-dialog-sync.p-dialog .bee-info-neutral-label {
    margin-top: 1rem;
    display: block;
}

/* primary */

.bee-audit-dialog-sync-primary.p-dialog .p-dialog-header {
    background-color: var(--primary-color)
}

.bee-audit-dialog-sync-primary.p-dialog .p-dialog-header .p-dialog-title {
    color: var(--primary-color-text);
}

.bee-audit-dialog-sync-primary.p-dialog .p-dialog-header .p-dialog-header-icons button {
    color: var(--primary-color-text);
}

.bee-audit-dialog-sync-primary.p-dialog .p-dialog-header-close:enabled:hover {
    background: var(--primary-light-color) !important;
    color: var(--primary-color-text) !important;
}

.bee-audit-dialog-sync-primary.p-dialog .p-dialog-header-close:enabled:active {
    background: var(--primary-color-68) !important;
    color: var(--primary-color-text) !important;
}

.bee-audit-dialog-sync-primary.p-dialog .p-dialog-header-close:enabled:focus {
    background: var(--primary-color-76) !important;
    color: var(--primary-color-text) !important;
}

/* secondary */

.bee-audit-dialog-sync-secondary.p-dialog .p-dialog-header {
    background-color: var(--secondary-color)
}

.bee-audit-dialog-sync-secondary.p-dialog .p-dialog-header .p-dialog-title {
    color: var(--secondary-color-text);
}

.bee-audit-dialog-sync-secondary.p-dialog .p-dialog-header .p-dialog-header-icons button {
    color: var(--secondary-color-text);
}

.bee-audit-dialog-sync-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: var(--secondary-light-color);
    color: var(--secondary-color-text);
}

.bee-audit-dialog-sync-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:active {
    background: var(--secondary-color-68);
    color: var(--secondary-color-text);
}

.bee-audit-dialog-sync-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:focus {
    background: var(--secondary-color-76);
    color: var(--secondary-color-text);
}


/* responsive */

@media (max-width:576px) {
    .bee-audit-dialog-sync.p-dialog {
        width: 90vw;
    }
}

@media (min-width:576px) and (max-width:650px) {
    .bee-audit-dialog-sync.p-dialog {
        width: 65vw;
    }
}