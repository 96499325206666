.bee-audit-offline-select-table {
  padding: 10px 0px;
}

.bee-audit-offline-select-table
  .p-datatable-tbody
  .p-datatable-emptymessage
  td {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  padding-left: 1em !important;
}

.bee-audit-offline-select-table .p-datatable-wrapper {
  border-radius: 4px;
  overflow: hidden;
}

.bee-audit-offline-select-table table th:first-child {
  padding-left: 1em !important;
}

.bee-audit-offline-select-table table th:last-child,
.bee-audit-offline-select-table table td:last-child {
  padding-right: 1em !important;
}

.bee-audit-offline-select-table
  table
  .p-datatable-tbody
  .p-rowgroup-header
  .bee-audit-offline-select-rowgroup-header {
  padding-left: 0.375rem;
}

.bee-audit-offline-select-table
  table
  .p-datatable-tbody
  .p-rowgroup-header
  .bee-audit-offline-select-rowgroup-header
  h3 {
  display: block;
  margin-bottom: 0.5rem;
}

.bee-audit-offline-select-table
  table
  .p-datatable-tbody
  .p-rowgroup-header
  .bee-audit-offline-select-rowgroup-header
  h4 {
  margin-top: 0.5rem;
  margin-bottom: 1em;
}

.bee-audit-offline-select-table .p-datatable-thead th,
.bee-audit-offline-select-table .p-datatable-tbody td,
.bee-audit-offline-select-table .p-datatable-tbody td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bee-audit-offline-select-table .p-datatable-thead th {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.bee-audit-offline-select-table .p-rowgroup-footer {
  display: none;
}

.bee-audit-offline-select-table .p-datatable-tbody td,
.bee-audit-offline-select-table .p-datatable-tbody td p {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

/* cols */

.bee-audit-offline-select-table-col-action {
  width: calc(2.25rem + 3px + 2rem);
  padding: 0 1em !important;
}

.bee-audit-offline-select-table-col-status {
  width: 4.25em;
  padding-left: 1em !important;
  text-align: center !important;
  padding-left: 0px !important;
}

.bee-audit-offline-select-table-col-nr {
  width: 20%;
}

.bee-audit-offline-select-table-col-type {
  width: 19%;
}

.bee-audit-offline-select-table-col-adapting {
  width: 5.9em;
}

.bee-audit-offline-select-table-col-name {
  width: 40%;
}

.bee-audit-offline-select-table-col-dienstleister {
  width: 20%;
}

.bee-audit-offline-select-table-col-rating {
  width: 8.5em;
}

/* dark */

.bee-audit-offline-select-table.bee-audit-offline-select-table-dark
  table
  .p-datatable-thead
  tr
  th {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

/* light */

.bee-audit-offline-select-table.bee-audit-offline-select-table-light
  table
  .p-datatable-thead
  tr
  th {
  font-size: 1.1em;
  color: var(--primary-color);
}

.bee-audit-offline-select-table.bee-audit-offline-select-table-light
  table
  .p-datatable-tbody
  .p-rowgroup-header {
  background-color: var(--primary-color-4);
}

.bee-audit-offline-select-table.bee-audit-offline-select-table-light
  table
  .p-datatable-tbody
  .p-rowgroup-header
  h3 {
  font-size: 1.1em;
}

/*  */

/* responsive */

@media (max-width: 450px) {
  .bee-audit-offline-select-table table thead {
    display: none;
  }
  .bee-audit-offline-select-table .p-rowgroup-header {
    font-size: 1.2em;
    background: var(--surface-a) !important;
  }
  .bee-audit-offline-select-table table,
  .bee-audit-offline-select-table table tbody,
  .bee-audit-offline-select-table table tr,
  .bee-audit-offline-select-table table td {
    display: block;
    width: 100%;
    text-align: left;
    float: left;
    clear: left;
    border: 0 none;
  }
  .bee-audit-offline-select-table table tbody tr {
    margin-bottom: 10px;
  }
  .bee-audit-offline-select-table
    table
    tbody
    tr:not(.p-rowgroup-header):nth-child(even) {
    background: var(--primary-color-4);
  }
  .bee-audit-offline-select-table table td p,
  .bee-audit-offline-select-table
    .p-datatable.p-datatable-sm
    .p-datatable-tbody
    > tr
    > td,
  .bee-audit-offline-select-table table td div {
    text-align: left !important;
    position: relative;
    border: none;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .bee-audit-offline-select-table table td p::before,
  .bee-audit-offline-select-table table td > div::before {
    content: attr(headername);
    padding: 0.4rem;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin: -0.4em 1em -0.6em 0.4rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  .bee-audit-offline-select-table-col-status {
    margin-top: 5px !important;
  }
  .bee-audit-offline-select-table
    .bee-audit-offline-select-table-col-status
    .p-knob {
    display: inline;
  }
  .bee-audit-offline-select-table
    .bee-audit-offline-select-table-col-status
    .p-knob
    svg {
    margin-bottom: -5px;
  }
  .bee-audit-offline-select-table table th:first-child,
  .bee-audit-offline-select-table table td:first-child {
    padding-left: 0.4rem !important;
  }
  .bee-audit-offline-select-table
    table
    > tbody
    > tr:first-child
    td:first-child {
    padding-top: 10px !important;
  }
  .bee-audit-offline-select-table table > tbody > tr td p {
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
  }
  .bee-audit-offline-select-table table tbody > tr > td:last-child {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    padding-bottom: 10px !important;
  }
}
