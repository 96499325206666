.bee-audit-dialog-basis.p-dialog {
  width: 70vw;
}

.bee-audit-dialog-basis.p-dialog .p-dialog-content {
  padding-top: 1em;
}

.bee-audit-dialog-basis.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.5rem;
}

.bee-audit-dialog-basis-btns {
  margin: 0px;
}

.bee-audit-dialog-basis-btns button {
  margin-left: 0.5rem;
}

/* primary */

.bee-audit-dialog-basis-primary.p-dialog .p-dialog-header {
  background-color: var(--primary-color);
}

.bee-audit-dialog-basis-primary.p-dialog .p-dialog-header .p-dialog-title {
  color: var(--primary-color-text);
}

.bee-audit-dialog-basis-primary.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  button {
  color: var(--primary-color-text);
}

.bee-audit-dialog-basis-primary.p-dialog .p-dialog-header-close:enabled:hover {
  background: var(--primary-light-color) !important;
  color: var(--primary-color-text) !important;
}

.bee-audit-dialog-basis-primary.p-dialog .p-dialog-header-close:enabled:active {
  background: var(--primary-color-68) !important;
  color: var(--primary-color-text) !important;
}

.bee-audit-dialog-basis-primary.p-dialog .p-dialog-header-close:enabled:focus {
  background: var(--primary-color-76) !important;
  color: var(--primary-color-text) !important;
}

/* secondary */

.bee-audit-dialog-basis-secondary.p-dialog .p-dialog-header {
  background-color: var(--secondary-color);
}

.bee-audit-dialog-basis-secondary.p-dialog .p-dialog-header .p-dialog-title {
  color: var(--secondary-color-text);
}

.bee-audit-dialog-basis-secondary.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  button {
  color: var(--secondary-color-text);
}

.bee-audit-dialog-basis-secondary.p-dialog
  .p-dialog-header
  .p-dialog-header-icon:enabled:hover {
  background: var(--secondary-light-color);
  color: var(--secondary-color-text);
}

.bee-audit-dialog-basis-secondary.p-dialog
  .p-dialog-header
  .p-dialog-header-icon:enabled:active {
  background: var(--secondary-color-68);
  color: var(--secondary-color-text);
}

.bee-audit-dialog-basis-secondary.p-dialog
  .p-dialog-header
  .p-dialog-header-icon:enabled:focus {
  background: var(--secondary-color-76);
  color: var(--secondary-color-text);
}

@media (max-width: 576px) {
  .bee-audit-dialog-basis.p-dialog {
    width: 95vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .bee-audit-dialog-basis.p-dialog {
    width: 90vw;
  }
}
