.bee-audit-form-grunddaten {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bee-audit-form-grunddaten .grid div {
  padding-top: 0px;
  padding-bottom: 0px;
}

.bee-audit-form-grunddaten .bee-validated-text-input,
.bee-audit-form-grunddaten .bee-validated-text-area,
.bee-audit-form-grunddaten .bee-validated-date-input {
  margin-bottom: 8px;
}
