.bee-audit-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 150;
}

.bee-audit-header > .grid {
  padding: 0rem;
  margin: 0px;
}

.bee-audit-header-dark,
.bee-audit-header-dark nav {
  background-color: var(--primary-color);
}

/* breadcrumb */

.bee-audit-header-head {
  border-bottom: 1px solid var(--surface-c);
}

.bee-audit-header-dark .bee-audit-header-head {
  border-bottom: 1px solid var(--surface-a);
}

.bee-audit-header-dark ul .p-menuitem-text,
.bee-audit-header-dark .p-menuitem-icon,
.bee-audit-header-dark ul .p-breadcrumb-chevron {
  color: var(--primary-color-text) !important;
}

/* breadcrumb buttons */

.bee-audit-header-signOut,
.bee-audit-header-save {
  width: 1.9rem;
  padding: 0px;
}

.bee-audit-header-signOut {
  margin-right: 1rem;
}

.bee-audit-header-dark .bee-audit-header-save button,
.bee-audit-header-dark .bee-audit-header-signOut button {
  color: var(--primary-color-text);
}

.bee-audit-header-dark .bee-audit-header-save button.p-button:hover,
.bee-audit-header-dark .bee-audit-header-signOut button.p-button:hover {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-light-color);
}

/* progress area */

.bee-audit-header-progress-area {
  height: 3rem;
  position: relative;
  justify-content: center;
  width: 100%;
  margin: 0px;
  padding: 0.5rem 0px 0.5rem 0;
}

.bee-audit-header-progress-text {
  font-size: small;
  text-align: center;
  position: relative;
  margin-top: -17px;
  padding: 0rem;
  font-weight: 500;
}

.bee-audit-header-progress-left-arrow {
  position: absolute;
  left: 5px;
  padding: 0px;
}

.bee-audit-header-progress-right-arrow {
  position: absolute;
  right: 5px;
  padding: 0px;
}

.bee-audit-header-progress-bar div {
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
}

.bee-audit-header-dark .bee-audit-header-progress-bar .bee-loadingbar-primary.p-progressbar .p-progressbar-value {
  background: var(--secondary-color);
}

.bee-audit-header-dark .bee-audit-header-progress-bar div {
  background: var(--surface-a);
}

.bee-audit-header-dark .bee-audit-header-progress-text {
  color: var(--primary-color-text);
}

/* warning styles */

.bee-audit-header-warning {
  padding: 0.6rem 1.5rem;
  display: inline-flex; 
  width: 100%;
}

.bee-audit-header-dark .bee-audit-header-warning:before {
  content: "";
  background-color: white;
}

.bee-audit-header-dark .bee-audit-header-warning {
  background-color: var(--primary-light-color);
}

.bee-audit-header-light .bee-audit-header-warning {
  background-color: var(--surface-200);
}

.bee-audit-header-light .bee-audit-header-warning span {
  color: var(--primary-color);
}

.bee-audit-header-light .bee-audit-header-warning i {
  padding: 0 0.5rem 0 0;
  color: var(--primary-color);
  display: flex;
  align-self: center;
}

.bee-audit-header-dark .bee-audit-header-warning i {
  padding: 0 0.5rem 0 0;
  color: var(--secondary-color) !important;
  display: flex;
  align-self: center;
}

.bee-audit-header-dark .bee-audit-header-warning span {
  color: var(--secondary-color);
}
