.bee-audit-lv-contract {
  .gp-td {
    width: 9em;
  }

  /* btns */
  .add-btn-td,
  .del-btn-td {
    width: 2.5rem;
    button {
      background-color: transparent;
    }
  }

  .ep-td {
    display: flex;
    align-content: flex-start;
  }

  /* pos menge, aks,  pos.nr alt*/
  .lv-contract-pos-infos {
    min-width: 7em;
  }

  /* readonly */
  .readOnly,
  &.readOnly,
  &.deactivated {
    .bee-txt-input,
    .bee-cur-input-field {
      border-color: transparent;
      background-color: transparent !important;
    }

    .bee-cur-input-label,
    .bee-txt-input-label {
      background-color: transparent !important;
    }

    &.del-btn-td {
      display: none;
    }
  }

  /* deactivated */
  &.deactivated .p-inputtext {
    color: var(--surface-500);
  }

  &.deactivated .title-td .bee-txt-input {
    text-decoration: line-through;
  }
}
