.bee-audit-dialog-snapshot.p-dialog {
    width: 50vw;
}

.bee-audit-dialog-snapshot.p-dialog .p-dialog-header {
    padding: 1.0rem;
}

.bee-audit-dialog-snapshot.p-dialog .p-dialog-content {
    padding-top: 1.0rem;
    padding-bottom: 0.75rem;
}

/* primary */

.bee-audit-dialog-snapshot-primary.p-dialog .p-dialog-header {
    background-color: var(--primary-color)
}

.bee-audit-dialog-snapshot-primary.p-dialog .p-dialog-header .p-dialog-title {
    color: var(--primary-color-text);
}

.bee-audit-dialog-snapshot-primary.p-dialog .p-dialog-header .p-dialog-header-icons button {
    color: var(--primary-color-text);
}

.bee-audit-dialog-snapshot-primary.p-dialog .p-dialog-header-icon:enabled:hover {
    background: var(--primary-light-color) !important;
    color: var(--primary-color-text) !important;
}

.bee-audit-dialog-snapshot-primary.p-dialog .p-dialog-header-icon:enabled:active {
    background: var(--primary-color-68) !important;
    color: var(--primary-color-text) !important;
}

.bee-audit-dialog-snapshot-primary.p-dialog .p-dialog-header-icon:enabled:focus {
    background: var(--primary-color-76) !important;
    color: var(--primary-color-text) !important;
}

/* secondary */

.bee-audit-dialog-snapshot-secondary.p-dialog .p-dialog-header {
    background-color: var(--secondary-color)
}

.bee-audit-dialog-snapshot-secondary.p-dialog .p-dialog-header .p-dialog-title {
    color: var(--secondary-color-text);
}

.bee-audit-dialog-snapshot-secondary.p-dialog .p-dialog-header .p-dialog-header-icons button {
    color: var(--secondary-color-text);
}

.bee-audit-dialog-snapshot-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: var(--secondary-light-color) !important;
    color: var(--secondary-color-text);
}

.bee-audit-dialog-snapshot-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:active {
    background: var(--secondary-color-68) !important;
    color: var(--secondary-color-text);
}

.bee-audit-dialog-snapshot-secondary.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:focus {
    background: var(--secondary-color-76) !important;
    color: var(--secondary-color-text);
}

@media (max-width:576px) {
    .bee-audit-dialog-snapshot.p-dialog {
        width: 85vw;
    }
}

@media (min-width:576px) and (max-width:768px) {
    .bee-audit-dialog-snapshot.p-dialog {
        width: 65vw;
    }
}