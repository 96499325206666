.bee-audit-overview {
  .bee-audit-overview-table {
    .p-treetable {
      margin-top: 1.25rem;

      /* header */

      .p-treetable-thead tr th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text) !important;
        &:last-child {
          border-top-right-radius: 4px;
        }
        &:first-child {
          border-top-left-radius: 4px;
        }
      }

      /* body */

      .p-treetable-tbody {
        tr {
          &.questionaire-root {
            background-color: var(--surface-200);
          }
          &.questionaire-main-category {
            background-color: var(--surface-50);
          }

          td {
            .bee-audit-overview-category-template {
              display: inline-flex;
            }

            &.bee-audit-overview-actionCol {
              .p-button {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }

      /* col-widths */
      .bee-audit-overview-categoryCol {
        width: 50%;
      }
      .bee-audit-overview-ratingCol {
        width: 9rem;
      }
      .bee-audit-overview-progressCol {
        width: 40%;
      }
      .bee-audit-overview-actionCol {
        padding-left: 2rem;
        padding-right: 0.25rem;
        width: calc(2.25rem + 3px + 2rem + 1.5rem);
      }
    }
  }
}
