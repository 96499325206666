.bee-audit-lv-comment {
  .add-comment-btn-td {
    width: 3.25rem;
    padding-left: 0.5rem;

    button {
      padding: 0.75rem 0.75rem;
      width: 2.5rem;
    }
  }
  .open-comment-btn-td {
    display: flex;
    justify-content: center;
  }
  .open-comment-btn-td,
  .comment-btn-platzhalter {
    margin: 0.5rem;
  }

  .bee-icn-btn-light {
    background-color: transparent;
  }

  .ta-comment-td {
    .p-scrollpanel {
      width: 100%;
      height: 7rem;
      .p-scrollpanel-content {
        height: 100%;
        padding: 0 18px 0px 0;
        /* hide scrollbar  */

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }
      }
    }
  }

  /* deactivated */
  &.deactivated .p-inputtext {
    color: var(--surface-500);
  }
}
