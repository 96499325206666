/* progress spinner */

  @keyframes p-progress-spinner-color {
    100%, 0% {
        stroke: var(--spinner-animation-start);
    }
    40% {
        stroke: var(--spinner-animation-40);
    }
    66% {
        stroke: var(--spinner-animation-66);
    }
    80%, 90% {
        stroke: var(--spinner-animation-end);
        stroke: var(--spinner-animation-end);
    }
}