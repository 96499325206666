.bee-audit-question {
  min-height: 100vh;
  padding-bottom: 85px;
  position: relative;

  /* headline */
  .bee-audit-question-category-headline {
    margin: 1.5rem 1.5rem 0.5rem 1.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;

    /* knob */
    .bee-audit-question-category-headline-knob {
      padding: 0.5rem 0.3rem 0.3rem 0.3rem;

      .p-knob {
        transform: scale(1.3);
      }
    }

    /* dropdown */
    .bee-audit-q-category-dropdown {
      padding-top: 0px;
      padding-bottom: 0px;

      .bee-dropdown-container {
        margin: 0px;

        .p-dropdown {
          width: 100%;
        }
      }
    }

    /* badge */
    .bee-audit-question-progress-badge {
      .p-badge {
        font-size: 1rem;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        float: right;
      }
      div {
        text-align: end;
        padding: 10px;
      }
    }
  }

  /* content */
  .bee-audit-question-content {
    margin-top: 0.5rem !important;

    & > h1 {
      display: inline;
    }

    .bee-audit-multi-choice,
    .bee-ta-container,
    .bee-flat-img-gallery,
    & > div:nth-last-child(2) {
      padding-bottom: 1.5rem;
    }
    .bee-audit-multi-choice {
      padding-top: 1.5rem;
    }

    .pi-info-circle {
      font-size: 1.5rem;
      padding-left: 0.75rem;
    }

    /* comments section */
    .bee-audit-question-comment {
      .bee-headline {
        margin-bottom: 0.5rem;
      }

      & > .bee-content-paragraph {
        display: inline-block;
        margin: 0rem 0.5rem 1rem 0.5rem;
      }

      /*stylling if rating is bad */
      .bee-audit-question-comment-info {
        margin-bottom: 1rem;

        i {
          transform: rotate(180deg);
          color: var(--secondary-dark-color);
          display: inline-block;
        }
        & > .bee-content-paragraph {
          display: inline;
          margin: 0rem 0.5rem 0rem 0.5rem;
        }
      }

      &.bee-audit-question-comment-enforce {
        .p-inputtext {
          border-color: var(--secondary-dark-color);
        }
      }
    }
  }

  /* footer */
  .bee-audit-question-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: var(--primary-color);
    padding: 0.25rem;
    margin: 0;
    z-index: 100;
  }
}
