.bee-audit-dialog-auffalligk.p-dialog {
  width: 70vw;

  /* header */
  .p-dialog-header .p-dialog-title {
    font-size: 1.5rem;
    font-weight: 800;
    padding: 0.5rem;
  }

  /* content */
  .p-dialog-content {
    padding-top: 1em;
  }

  /* type */
  .bee-audit-dialog-auffalligk-type .bee-content-paragraph {
    margin: 0 0.5rem;
  }

  .bee-audit-dialog-auffalligk-imgs h3,
  .bee-audit-dialog-auffalligk-docs h3 {
    font-size: 1.3em;
    margin: 0.5em 0em;
  }

  /* chip area */
  .bee-chip-area {
    border: none;
  }

  /* docs */
  .bee-audit-dialog-auffalligk-docs {
    .bee-headline {
      margin-bottom: 0rem;
    }

    button {
      margin-left: 2px;
    }
  }

  /* btns */
  .bee-audit-dialog-auffalligk-btns {
    div {
      margin: 0px;
    }
    button {
      margin-left: 0.5rem;
    }
  }
}

/* primary */
.bee-audit-dialog-auffalligk-primary.p-dialog .p-dialog-header {
  background-color: var(--primary-color);

  .p-dialog-title {
    color: var(--primary-color-text);
  }

  .p-dialog-header-icons button {
    color: var(--primary-color-text);
  }

  .p-dialog-header-icon {
    &:enabled:hover {
      background: var(--primary-light-color) !important;
      color: var(--primary-color-text) !important;
    }
    &:enabled:active {
      background: var(--primary-color-68) !important;
      color: var(--primary-color-text) !important;
    }
    &:enabled:focus {
      background: var(--primary-color-76) !important;
      color: var(--primary-color-text) !important;
    }
  }
}

/* secondary */

.bee-audit-dialog-auffalligk-secondary.p-dialog {
  .p-dialog-header {
    background-color: var(--secondary-color);
    .p-dialog-header-icons button {
      color: var(--secondary-color-text);
    }

    .p-dialog-header-icon {
      &:enabled:hover {
        background: var(--secondary-light-color);
        color: var(--secondary-color-text);
      }
      &:enabled:active {
        background: var(--secondary-color-68);
        color: var(--secondary-color-text);
      }
      &:enabled:focus {
        background: var(--secondary-color-76);
        color: var(--secondary-color-text);
      }
    }
  }
  .p-dialog-title {
    color: var(--secondary-color-text);
  }
}

/* responsive */

@media (max-width: 576px) {
  .bee-audit-dialog-auffalligk.p-dialog {
    width: 95vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .bee-audit-dialog-auffalligk.p-dialog {
    width: 90vw;
  }
}
