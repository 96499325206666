.bee-audit-lv-quantity {
  .gp-td {
    width: 9em;
  }

  /* colored */
  &.colored {
    &.coloredGp {
      .gp-td .p-inputtext {
        color: var(--tertiary-color);
        font-weight: 600;
      }
    }
    &.coloredMenge {
      .menge-td .p-inputtext {
        color: var(--tertiary-color);
        font-weight: 600;
      }
    }
    &.coloredEp {
      .ep-td .p-inputtext {
        color: var(--tertiary-color);
        font-weight: 600;
      }
    }
    &.coloredEinheit {
      .einheit-td .p-inputtext {
        color: var(--tertiary-color);
        font-weight: 600;
      }
    }
  }

  /* readOnly */

  &.readOnly,
  .readOnly,
  &.deactivated {
    .bee-txt-input,
    .bee-cur-input-field,
    .bee-num-input {
      border-color: transparent;
      background-color: transparent !important;
    }

    .bee-cur-input-label,
    .bee-txt-input-label {
      background-color: transparent !important;
    }
  }

  /* deactivated */
  &.deactivated .p-inputtext,
  &.deactivated .bee-checkbox-input,
  &.deactivated .bee-icon::before {
    color: var(--surface-500);
  }
  &.deactivated .gp-td .bee-cur-input-field {
    text-decoration: line-through;
  }
}
