.bee-audit-select-table {
  padding: 10px 0px;
}

.bee-audit-select-table .p-datatable-tbody .p-datatable-emptymessage td {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.bee-audit-select-table .p-datatable-wrapper {
  border-radius: 4px;
  overflow: hidden;
}

.bee-audit-select-table table th:first-child,
.bee-audit-select-table table td:first-child {
  padding-left: 1em !important;
}

.bee-audit-select-table table th:last-child,
.bee-audit-select-table table td:last-child {
  padding-right: 1em !important;
}

.bee-audit-select-table-expander {
  width: 60px;
}

.bee-audit-select-table .p-datatable-thead th,
.bee-audit-select-table .p-datatable-tbody td,
.bee-audit-select-table .p-datatable-tbody td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bee-audit-select-table .p-datatable-thead th {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.bee-audit-select-table .p-datatable-tbody td {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
/* lock */

.bee-audit-select-table-col-lock .bee-audit-select-table-mouseover {
  display: none;
  color: var(--text-color);
  text-decoration: none;
  padding: 3px;
}

.bee-audit-select-table-col-lock
  .bee-icon:hover
  + .bee-audit-select-table-mouseover {
  display: block;
  position: absolute;
  z-index: 100;
  background-color: var(--secondary-color);
  color: var(--secondary-color-text);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  margin: -3.7em 1.4em;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 16px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* cols */

.p-datatable-tbody .bee-audit-select-table-col-status {
  text-align: center !important;
}

.bee-audit-select-table-col-status {
  width: 3.5em;
  padding-left: 0em !important;
}

.bee-audit-select-table-col-type {
  width: 19%;
}

.bee-audit-select-table-col-adapting {
  width: 5.9em;
}

.bee-audit-select-table-col-name {
  width: 38%;
}

.bee-audit-select-table-col-auditor {
  width: 20%;
}

.bee-audit-select-table-col-dienstleister {
  width: 23%;
}

.bee-audit-select-table-col-rating {
  width: 8.5em;
}

.bee-audit-select-table-col-lock {
  width: 40px;
  text-align: center !important;
  padding-right: 8px !important;
}

.bee-audit-select-table-col-action-twoBtn {
  width: calc(4.5rem + 3px + 2rem);
}

.bee-audit-select-table-col-action-threeBtn {
  width: calc(6.75rem + 3px + 2rem);
}

.bee-audit-select-table-col-action {
  text-align: right !important;
  padding-right: 1em !important;
}

.bee-audit-select-table-col-action-sync-btn {
  display: inline;
}

.bee-audit-select-table-col-action button {
  margin-right: 3px;
}

/* dark */

.bee-audit-select-table.bee-audit-select-table-dark
  table
  .p-datatable-thead
  tr
  th {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

/* light */

.bee-audit-select-table.bee-audit-select-table-light
  table
  .p-datatable-thead
  tr
  th {
  font-size: 1.1em;
  color: var(--primary-color);
}

.bee-audit-select-table.bee-audit-select-table-light
  table
  .p-datatable-tbody
  tr:nth-child(odd) {
  background: var(--primary-color-4);
}

/*inner table css*/

.bee-audit-select-table table .p-datatable-tbody .p-datatable-wrapper {
  padding: 0rem 0rem 1rem 2.5rem;
  font-size: 0.9em;
}

.bee-audit-select-innertable-message {
  padding: 0.7rem 1rem 1rem 2.5rem;
  font-size: 0.9em;
}

.bee-audit-select-table
  .p-datatable-thead
  .bee-audit-select-innertable-col-versions,
.bee-audit-select-table
  .p-datatable-thead
  .bee-audit-select-innertable-col-date,
.bee-audit-select-table
  .p-datatable-thead
  .bee-audit-select-innertable-col-action {
  padding-bottom: 0.5rem !important;
}

.bee-audit-select-innertable table td p {
  margin: 0px;
}

/* cols */

.bee-audit-select-innertable-col-action-oneBtn {
  width: 95px;
}

.bee-audit-select-innertable-col-action-oneBtn div {
  padding-left: 10px;
}

.bee-audit-select-innertable-col-action-twoBtn {
  width: 125px;
}

.bee-audit-select-innertable-col-date {
  width: 165px;
}

.bee-audit-select-innertable-col-versions {
  width: 90%;
}

td.bee-audit-select-innertable-col-action {
  text-align: right !important;
  padding-right: 0px !important;
}

th.bee-audit-select-innertable-col-versions,
th.bee-audit-select-innertable-col-date {
  font-weight: 800 !important;
}

.bee-audit-select-innertable-col-action button:first-child {
  text-align: right !important;
  margin-right: calc(2.25rem - 1em);
}

/* innertable dark */

.bee-audit-select-table.bee-audit-select-table-dark
  table
  .p-datatable-tbody
  tr
  th {
  background: var(--surface-a);
  color: var(--primary-color);
}

/* innertable light */

.bee-audit-select-table.bee-audit-select-table-light
  table
  .p-datatable-tbody
  .bee-audit-select-innertable
  tr,
.bee-audit-select-table.bee-audit-select-table-light
  table
  .p-datatable-tbody
  .bee-audit-select-innertable
  th {
  background: var(--surface-a);
  color: var(--primary-color);
  font-size: 1em;
}

/* responsive */

@media (max-width: 450px) {
  .bee-audit-select-table table thead,
  .bee-audit-select-innertable table thead {
    display: none;
  }
  .bee-audit-select-table table,
  .bee-audit-select-table table tbody,
  .bee-audit-select-table table tr,
  .bee-audit-select-table table td,
  .bee-audit-select-innertable table,
  .bee-audit-select-innertable table tbody,
  .bee-audit-select-innertable table tr,
  .bee-audit-select-innertable table td {
    display: block;
    width: 100%;
    text-align: left;
    float: left;
    clear: left;
    border: 0 none;
  }
  .bee-audit-select-table table tbody tr {
    margin-bottom: 10px;
  }
  .bee-audit-select-table table tbody tr:nth-child(odd) {
    background: var(--primary-color-4);
  }
  .bee-audit-select-table table td p,
  .bee-audit-select-table
    .p-datatable.p-datatable-sm
    .p-datatable-tbody
    > tr
    > td,
  .bee-audit-select-table table td div {
    text-align: left !important;
    border: none;
  }
  .bee-audit-select-table table td p::before,
  .bee-audit-select-table
    table
    td
    > div:not(.bee-audit-select-innertable-message, .bee-audit-select-innertable):before {
    content: attr(headername);
    padding: 0.4rem;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin: -0.4em 1em -0.6em 0.4rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  .bee-audit-select-table .bee-audit-select-table-col-status {
    margin-top: 5px !important;
    padding-left: 0.4rem !important;
  }
  .bee-audit-select-table .bee-audit-select-table-col-status .p-knob {
    display: inline;
  }
  .bee-audit-select-table .bee-audit-select-table-col-status .p-knob svg {
    margin-bottom: -5px;
  }
  .bee-audit-select-innertable-message {
    white-space: normal;
    padding: 1rem 1rem 0.6rem 2.5rem;
  }
  .bee-audit-select-innertable div {
    padding-bottom: 0px !important;
    padding-top: 0.5rem !important;
  }
  .bee-audit-select-table table th:first-child,
  .bee-audit-select-table table td:first-child {
    padding-left: 0.4rem !important;
  }
  .bee-audit-select-table table > tbody > tr:first-child td:first-child {
    padding-top: 10px !important;
  }
  .bee-audit-select-table table > tbody > tr td p {
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
  }
  .bee-audit-select-table table tbody > tr > td:last-child {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    padding-bottom: 10px !important;
  }
  /* correct lock hover */
  .bee-audit-select-table-col-lock
    .bee-icon:hover
    + .bee-audit-select-table-mouseover {
    margin: -3.7em 13.4em;
  }
}
