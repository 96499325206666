.bee-audit-setup-leistung-table {
    padding-bottom: 10px;
}

.bee-audit-setup-leistung-table .p-datatable-wrapper {
    border-radius: 4px;
    overflow: hidden;
}

.bee-audit-setup-leistung-table .p-datatable-thead th, .bee-audit-setup-leistung-table .p-datatable-tbody td, .bee-audit-setup-leistung-table .p-datatable-tbody td p, .bee-audit-setup-leistung-table .p-datatable-tbody td h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bee-audit-setup-leistung-table .p-datatable-thead th {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.bee-audit-setup-leistung-table .p-datatable-tbody td {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.bee-audit-setup-leistung .p-datatable-tbody .p-datatable-emptymessage td {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
    padding-left: 1em !important;
}

/* cols */

.bee-audit-setup-leistung-table-col-stand {
    width: 4.5em;
    padding-left: 1em !important;
}

.bee-audit-setup-leistung-table-col-selection {
    width: 75px;
    text-align: center !important;
    padding-right: 20px !important;
}

.bee-audit-setup-leistung-table-col-text {
    width: 60%;
}

.bee-audit-setup-leistung-table-col-text p {
    font-size: 0.8em;
    margin-bottom: 0px;
}

.bee-audit-setup-leistung-table-col-text h4 {
    margin-top: 5px;
    margin-block-end: 0.8em;
}

.bee-audit-setup-leistung-table-col-weight {
    width: 40%;
    padding-right: 1em !important;
}

.bee-audit-setup-leistung-table-col-weight label {
    display: none
}

/* dark */

.bee-audit-setup-leistung-table.bee-audit-setup-leistung-table-dark table .p-datatable-thead tr th {
    background: var(--primary-color);
    color: var( --primary-color-text);
}

/* light */

.bee-audit-setup-leistung-table.bee-audit-setup-leistung-table-light table .p-datatable-thead tr th {
    font-size: 1.1em;
    color: var(--primary-color)
}

.bee-audit-setup-leistung-table.bee-audit-setup-leistung-table-light table .p-datatable-tbody tr:nth-child(odd) {
    background-color: var(--primary-color-4)
}

/* responsive  */

@media(max-width:450px) {
    .bee-audit-setup-leistung-table table thead {
        display: none;
    }
    .bee-audit-setup-leistung-table table, .bee-audit-setup-leistung-table table tbody, .bee-audit-setup-leistung-table table tr, .bee-audit-setup-leistung-table table td {
        display: block;
        width: 100%;
        text-align: left;
        float: left;
        clear: left;
        border: 0 none;
    }
    .bee-audit-setup-leistung-table table tbody tr {
        margin-bottom: 10px;
    }
    .bee-audit-setup-leistung-table table tbody tr:nth-child(odd) {
        background: var(--primary-color-4)
    }
    .bee-audit-setup-leistung-table table td p, .bee-audit-setup-leistung-table .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td, .bee-audit-setup-leistung-table table td div, .bee-audit-setup-leistung-table .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td, .bee-audit-setup-leistung-table table td h4 {
        text-align: left !important;
        position: relative;
        border: none;
    }
    .bee-audit-setup-leistung-table table td>div::before, .bee-audit-setup-leistung-table table td h4::before {
        content: attr(headername);
        padding: .4rem;
        width: 30%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        margin: -.4em 1em -.6em 0.4rem;
        font-weight: bold;
        color: var(--primary-color)
    }
    .bee-audit-setup-leistung-table-col-text p {
        display: inline;
    }
    .bee-audit-setup-leistung-table-col-weight div div {
        display: inline-block;
    }
    .bee-audit-setup-leistung-table-col-selection {
        padding-right: inherit !important;
    }
    .bee-audit-setup-leistung-table table th:first-child, .bee-audit-setup-leistung-table table td:first-child {
        padding-left: .4rem !important;
    }
    .bee-audit-setup-leistung-table table>tbody>tr:first-child td:first-child {
        padding-top: 10px !important;
    }
    .bee-audit-setup-leistung-table table>tbody>tr td>div {
        margin-top: 0.75em !important;
        margin-bottom: 0.75em !important;
    }
    .bee-audit-setup-leistung-table table tbody>tr>td:last-child {
        padding-left: .4rem !important;
        padding-right: .4rem !important;
        padding-bottom: 10px !important;
    }
}