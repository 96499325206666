.bee-audit-tabs .bee-select-btn-primary,
.bee-audit-tabs .bee-select-btn-secondary {
  background: var(--surface-b);
  margin-bottom: 10px;
  overflow: hidden;
}

.bee-audit-tabs .bee-select-btn-primary .p-button span {
  font-size: clamp(0.7rem, calc(-0.875rem + 4vw), 1rem);
}

.bee-audit-tabs .bee-select-btn-secondary .p-button span {
  font-size: clamp(0.7rem, calc(-0.875rem + 4vw), 1rem);
}

.bee-select-btn-primary .p-button:first-child:nth-last-child(4),
.bee-select-btn-primary .p-button:first-child:nth-last-child(4) ~ .p-button,
.bee-select-btn-secondary .p-button:first-child:nth-last-child(4),
.bee-select-btn-secondary .p-button:first-child:nth-last-child(4) ~ .p-button {
  padding-left: 3vw;
  padding-right: 3vw;
  display: inline-block;
  line-height: 1;
  line-height: clamp(0.7rem, calc(-0.875rem + 3vw), 1rem);
}

.bee-select-btn-primary .p-button:first-child:nth-last-child(5),
.bee-select-btn-primary .p-button:first-child:nth-last-child(5) ~ .p-button,
.bee-select-btn-secondary .p-button:first-child:nth-last-child(5),
.bee-select-btn-secondary .p-button:first-child:nth-last-child(5) ~ .p-button {
  padding-left: 0.6em;
  padding-right: 0.6em;
  display: inline-block;
  line-height: clamp(0.7rem, calc(-0.875rem + 3vw), 1rem);
}

.bee-audit-tabs .p-button span {
  padding-left: 5px;
}

.bee-audit-tabs .p-button span,
.bee-audit-tabs .p-button i {
  vertical-align: middle;
}

/* responsive */

@media (max-width: 580px) {
  .bee-audit-tabs .p-button span {
    display: none;
  }
  .bee-audit-tabs .p-button.p-highlight span {
    display: inline-block;
  }
}

@media (max-width: 470px) {
  .bee-audit-tabs .p-button span {
    display: none;
  }
  .bee-audit-tabs .p-button.p-highlight span {
    display: none;
  }
}

@media (min-width: 850px) {
  .bee-select-btn-primary .p-button:first-child:nth-last-child(4),
  .bee-select-btn-primary .p-button:first-child:nth-last-child(4) ~ .p-button,
  .bee-select-btn-secondary .p-button:first-child:nth-last-child(4),
  .bee-select-btn-secondary
    .p-button:first-child:nth-last-child(4)
    ~ .p-button {
    padding-left: 3vw;
    padding-right: 3vw;
  }
  .bee-select-btn-primary .p-button:first-child:nth-last-child(5),
  .bee-select-btn-primary .p-button:first-child:nth-last-child(5) ~ .p-button,
  .bee-select-btn-secondary .p-button:first-child:nth-last-child(5),
  .bee-select-btn-secondary
    .p-button:first-child:nth-last-child(5)
    ~ .p-button {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}
